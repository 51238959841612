import RowLocation from '../../topics/Weather/components/LiveData/components/RowLocation'
import useSelectLocation from '../../modules/use-select-location'
import { useEffect } from 'react'

const SelectLocation = ({
  onChange = ({}) => {},
  onStart = ({}) => {},
  initialLocation,
  initialCoordinates,
  ...props
}) => {
  const {
    loading: loadingLocation,
    communes,
    selectedOption,
    onUpdateLocation,
    setLocation,
    setCoordinates,
  } = useSelectLocation()

  useEffect(() => {
    if (initialLocation && !loadingLocation) {
      const locationDetail = setLocation(initialLocation)
      onStart({ selectedLocation: locationDetail })
    }
  }, [initialLocation, loadingLocation])

  useEffect(() => {
    if (initialCoordinates && !loadingLocation) {
      const locationDetail = setCoordinates(initialCoordinates)
      onStart({ selectedLocation: locationDetail })
    }
  }, [initialCoordinates, loadingLocation])

  const onChangeLocation = (selectedOption) => {
    const locationDetail = onUpdateLocation(selectedOption)
    onChange({ selectedLocation: locationDetail })
  }

  return (
    <RowLocation
      values={communes}
      value={selectedOption}
      onChange={onChangeLocation}
      isDisabled={loadingLocation}
      placeholder={'select_your_commune'}
      {...props}
    />
  )
}

export default SelectLocation
