import { useCookies } from 'react-cookie'

const useCookieGeoLocation = () => {
  const COOKIE_NAME = 'dlabGeoLocation'

  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME])

  const save = (location) => {
    if (!location) {
      return false
    }

    const expires = new Date('9999-12-31T23:59:59.999Z')
    setCookie(COOKIE_NAME, location, {
      path: '/',
      expires,
    })
  }

  return {
    save,
    cookie: cookies?.dlabGeoLocation,
    remove: () => removeCookie(COOKIE_NAME),
  }
}

export default useCookieGeoLocation
