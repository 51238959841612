import React, { Component } from 'react'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate'
import Select from '../../../../../../components/Select'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const SingleElement = styled.div`
  flex: 1;
`

class RowLocation extends Component {
  shouldComponentUpdate() {
    return true
  }
  render() {
    const { value, values, onChange, placeholder, isDisabled, ...props } = this.props
    return (
      <Wrapper {...props}>
        <SingleElement>
          <Select
            value={value}
            onChange={onChange}
            values={values}
            placeholder={translate(placeholder)}
            isSearchable={true}
            isDisabled={isDisabled}
          />
        </SingleElement>
      </Wrapper>
    )
  }
}

RowLocation.defaultProps = {
  onChange: () => {},
}

RowLocation.propTypes = {
  ...Select.propTypes,
}

export default RowLocation

export { Wrapper as WrapperRowLocation, SingleElement as SingleElementRowLocation }
