import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import consoleLogger from '../console-logger'
import communes from '../../topics/Weather/widgets/WidgetNearMeWeather/communes'
import { isLocationInSouthTyrol } from '../location'
import hasItems from '../has-items'
const geolib = require('geolib')

const useSelectLocation = () => {
  const [consoleLog] = consoleLogger('Geo')
  const [selectedOption, setSelectedOption] = useState()
  const [selectedLocation, setSelectedLocation] = useState()
  const response = useQuery({
    queryKey: ['communes', { external: true }, 'https://cdn.suedtirol.live/geo/mapping_locations_dlab_fada.json'],
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  const setLocation = (location) => {
    const commune = communes.getCommune(location).trim()
    const subLocation = communes.getSubLocation(location).trim()

    const slcLocationDetail = response?.data && response?.data[location]

    const locationDetail = {
      ...slcLocationDetail,
      commune,
      subLocation,
      location,
    }

    setSelectedLocation(locationDetail)
    setSelectedOption(communes.labelToSelected(location))

    return locationDetail
  }

  const setCoordinates = (coordinates) => {
    if (isLocationInSouthTyrol(coordinates.latitude, coordinates.longitude)) {
      let justLatLongValues = coordinates
      let minDistanceFinderHelper
      let nearestLocation
      let nearestLocationData
      const mappedLocations = response?.data && Object.keys(response?.data)
      if (hasItems(mappedLocations)) {
        mappedLocations.forEach((location) => {
          const locationData = response?.data[location]

          let latLongStation = {
            latitude: parseFloat(locationData.latitude),
            longitude: parseFloat(locationData.longitude),
          }

          if (minDistanceFinderHelper === undefined) {
            minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
            nearestLocation = location
            nearestLocationData = locationData
          }

          if (geolib && minDistanceFinderHelper > geolib.getDistance(justLatLongValues, latLongStation)) {
            minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
            nearestLocation = location
            nearestLocationData = locationData
          }
        })
      }
      if (nearestLocation !== undefined) {
        const commune = communes.getCommune(nearestLocation).trim()
        const subLocation = communes.getSubLocation(nearestLocation).trim()

        const locationDetail = {
          ...nearestLocationData,
          commune,
          subLocation,
          location: nearestLocation,
        }

        setSelectedLocation(locationDetail)
        setSelectedOption(communes.labelToSelected(nearestLocation))

        return locationDetail
      }
    } else {
      const defaultLocation = 'Bozen (Bozen)'
      const defaultLocationData = response?.data && response?.data[defaultLocation]
      const commune = communes.getCommune(defaultLocation).trim()
      const subLocation = communes.getSubLocation(defaultLocation).trim()

      const locationDetail = {
        ...defaultLocationData,
        commune,
        subLocation,
        location: defaultLocation,
      }

      setSelectedLocation(locationDetail)
      setSelectedOption(communes.labelToSelected(defaultLocation))

      return locationDetail
    }
  }

  const onUpdateLocation = (selectedLocation) => {
    const locationDetail = setLocation(selectedLocation.label)
    return locationDetail
  }
  consoleLog('Selected location', selectedLocation)

  return {
    loading: response.isLoading,
    communes: communes.selectable,
    selectedOption,
    selectedLocation,
    onUpdateLocation,
    setLocation,
    setCoordinates,
  }
}

export default useSelectLocation
