import React from 'react'
import styled from 'styled-components'

import skeletonFade from '../../../../modules/mixins/skeleton-fade'
import HeaderField from '../../components/HeaderField'
import TitleTopic from '../../../../components/TitleTopic'

const SuperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
`

const TextDiv = styled.div`
  ${skeletonFade};
  background-color: lightgrey;
  width: 55%;
  height: 10px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 90%;
  }
`

const StyledDiv2 = styled.div`
  ${skeletonFade};
  width: 50%;
  margin-top: 50px;
  height: 300px;
  margin-bottom: 25px;
  margin-top: 25px;
  background-color: lightgrey;

  margin-left: 5%;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: auto;
    width: 90%;
  }
`
const StyledDiv3 = styled.div`
  ${skeletonFade};
  width: 30%;
  margin-top: 50px;
  height: 300px;
  margin-bottom: 25px;
  margin-top: 25px;
  background-color: lightgrey;

  margin-left: auto;
  margin-right: 5%;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: auto;
    width: 90%;
  }
`

const StyledTitle = styled.div`
  ${skeletonFade};
  width: 100%;
  height: 75px;
  margin-bottom: 20px;
  background-color: lightgrey;

  margin-left: auto;
  margin-right: auto;
`

const Wrapper = styled.div``
const SkeletonCouncilElection = ({ location, ...props }) => (
  <Wrapper {...props}>
    <HeaderField location={location} />
    <StyledTitle />
    <TextDiv />
    <SuperDiv>
      <StyledDiv2 />
      <StyledDiv3 />
    </SuperDiv>
    <SuperDiv>
      <StyledDiv2 />
      <StyledDiv3 />
    </SuperDiv>
  </Wrapper>
)
SkeletonCouncilElection.propTypes = {}
SkeletonCouncilElection.defaultProps = {}
export default SkeletonCouncilElection
