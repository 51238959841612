import checkForValueInUserAgent from '../check-for-value-in-useragent'
import isStolApp from '../is-stolapp'
const semver = require('semver')

const getAndroidVersion = (userAgent) => {
  if (!userAgent || !userAgent.length || !userAgent.length > 0) {
    return ''
  }

  let currentVersion = ''
  let searchString = 'gps'
  let indexOfGps = userAgent.indexOf(searchString)
  if (indexOfGps && indexOfGps >= 1) {
    let lastIndexOfVersion = indexOfGps - 1
    let currentVersionRaw = userAgent.substring(0, lastIndexOfVersion)

    let firstIndexOfVersion = currentVersionRaw.lastIndexOf(' ')
    currentVersionRaw = userAgent.substring(firstIndexOfVersion, lastIndexOfVersion).trim()
    if (currentVersionRaw.includes('-')) {
      currentVersionRaw = currentVersionRaw.substring(0, currentVersionRaw.indexOf('-'))
    }

    if (semver.valid(cleanVersions(currentVersionRaw))) {
      currentVersion = cleanVersions(currentVersionRaw)
    }
  }
  return currentVersion
}

const getIosVersion = (userAgent) => {
  if (!userAgent || !userAgent.length || !userAgent.length > 0) {
    return ''
  }
  let searchString = 'stolapp'
  let currentVersion = ''
  let indexOfStolApp = userAgent.indexOf(searchString)
  if (indexOfStolApp && indexOfStolApp >= 1) {
    let firstIndexOfVersion = indexOfStolApp + searchString.length + 1
    let lastIndexOfVersion = userAgent.substring(firstIndexOfVersion).indexOf(' ')
    let currentVersionRaw = userAgent.substring(firstIndexOfVersion).substring(0, lastIndexOfVersion)
    if (currentVersionRaw.includes('-')) {
      currentVersionRaw = currentVersionRaw.substring(0, currentVersionRaw.indexOf('-'))
    }
    if (semver.valid(cleanVersions(currentVersionRaw))) {
      currentVersion = cleanVersions(currentVersionRaw)
    }
  }
  return currentVersion
}

const cleanVersions = (version) => {
  let returnVersion = version
  if (version && version.length > 0 && version.indexOf('.')) {
    if (version.split('.').length - 1 == 1) {
      returnVersion = returnVersion + '.0'
    }
  }
  return returnVersion
}

const isAppVersionReady = (androidVersion, iosVersion) => {
  let androidCleanVersion = cleanVersions(androidVersion)
  let iosCleanVersion = cleanVersions(iosVersion)
  if (
    !androidCleanVersion ||
    !androidCleanVersion.length > 0 ||
    !iosCleanVersion ||
    !iosCleanVersion.length > 0 ||
    !semver.valid(androidCleanVersion) ||
    !semver.valid(iosCleanVersion)
  ) {
    return false
  }
  let isReady = false
  let isAndroid = false

  let isIos = false
  let currentVersion = ''
  let userAgent = ''

  if (semver && window && window.navigator && window.navigator.userAgent && isStolApp()) {
    userAgent = window.navigator.userAgent
    isAndroid = checkForValueInUserAgent('Android') || checkForValueInUserAgent('android')
    isIos =
      checkForValueInUserAgent('iphone') ||
      checkForValueInUserAgent('ipad') ||
      checkForValueInUserAgent('ipod') ||
      checkForValueInUserAgent('iPhone') ||
      checkForValueInUserAgent('iPad')
    if (isAndroid) {
      currentVersion = getAndroidVersion(userAgent)
      if (currentVersion && currentVersion.length > 0) {
        isReady = semver.gte(currentVersion, androidCleanVersion)
      }
    }
    if (isIos) {
      currentVersion = getIosVersion(userAgent)
      if (currentVersion && currentVersion.length > 0) {
        isReady = semver.gte(currentVersion, iosCleanVersion)
      }
    }
  }

  return isReady
}

export default isAppVersionReady
