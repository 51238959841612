import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import Text from '../../../../components/Text'

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;

  ${(props) => {
    if (props.theme.mode.includes('mobile')) {
      return css`
        background: url('https://s3-italy.brennercom.it/stol-images/img/speciale/gemeinderatswahlen/gw2020-bg.jpg');
      `
    }
  }}
`

const Banner = styled.div`
  display: block;
  width: 100%;

  ${(props) => {
    if (props.theme.mode.includes('mobile')) {
      return css`
        height: 250px;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        display: flex;
      `
    }
  }}
`

const Image = styled.img`
  display: block;
  width: 100%;
  max-height: 100%;
`

const ButtonGroup = styled(Text)`
  position: absolute;
  left: calc(4.5% - 0.25rem);
  bottom: calc(17% - 0.25rem);

  color: #fff;

  ${(props) => {
    if (props.theme.mode !== 'desktop') {
      const styles = css`
        position: relative;
        left: unset;
        bottom: unset;
        width: 100%;
      `

      if (props.theme.mode.includes('mobile')) {
        return css`
          ${styles}
          text-align: center;
          background-color: rgba(0, 0, 0, 0.1);
        `
      } else {
        return css`
          ${styles}
          background-color: #c21c1c;
        `
      }
    }
  }}
`

const NavLink = styled.a`
  display: inline-block;
  padding: 0.5em;
  border: 1px solid #fff;
  text-transform: uppercase;
  border-radius: 5px !important;
  margin: 0.25rem;

  color: inherit;
  text-decoration: none;
  ${(props) =>
    props.finished &&
    css`
      background-color: rgba(0, 0, 0, 0.3);
    `}

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    text-decoration: none;
  }
  &:active {
    color: white;
    text-decoration: none;
  }
  &:visited {
    color: white;
    text-decoration: none;
  }

  ${(props) => {
    if (props.theme.mode.includes('mobile')) {
      return css`
        display: block;
        border: none;
        margin: 0;
        border-radius: 0 !important;
        padding: 13px;
        font-size: 1rem;
      `
    }
    if (props.theme.mode === 'tablet') {
      return css`
        display: inline-block;
        width: 33.33%;
        text-align: center;
        border: none;
        margin: 0;
        border-radius: 0 !important;
        box-sizing: border-box;
        font-size: 1rem;
      `
    }
  }}
`

const HeaderField = ({ location, ...props }) => {
  const themeContext = useContext(ThemeContext)

  return (
    <Wrapper>
      <Banner {...props}>
        {themeContext.mode.includes('mobile') && (
          <Image src="https://s3-italy.brennercom.it/stol-images/img/speciale/gemeinderatswahlen/gw2020-mobile-fg.svg" />
        )}
        {!themeContext.mode.includes('mobile') && (
          <Image src="https://s3-italy.brennercom.it/stol-images/img/speciale/gemeinderatswahlen/gw2020-desktop.jpg" />
        )}
      </Banner>
      <ButtonGroup>
        <NavLink href="/spezial/gemeinderatswahlen">Alle News</NavLink>
        <NavLink
          finished={location && location.includes('einegemeinde')}
          href="/p/gemeinderatswahlen-einegemeinde?gemeinde=8"
        >
          Gemeinden im Detail
        </NavLink>
        <NavLink
          finished={location && location.includes('allebuergermeister')}
          href="/p/gemeinderatswahlen-allebuergermeister"
        >
          Alle Bürgermeister
        </NavLink>
      </ButtonGroup>
    </Wrapper>
  )
}
HeaderField.propTypes = {}
HeaderField.defaultProps = {}
export default HeaderField
