import React from 'react'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ScreenCouncilElection from './screenCouncilElection'
import TitleTopic from '../../components/TitleTopic'
import isStolApp from '../../modules/is-stolapp'
import bruneck from './bruneck.json'
import loadable from '@loadable/component'
const LayoutDefault = loadable(() =>
  import(/* webpackChunkName: "LayoutDefault" , webpackPrefetch: true */ '../../layouts/Default'),
)

const StyledTitleTopic = styled(TitleTopic)`
  margin-bottom: 0px;
`

const StyledTitleTopic2 = styled(TitleTopic)`
  margin-bottom: 0px;
  font-size: 16px;
  height: 60px;
`
const CouncilElection = ({ initialData, match, location, layout, ...props }) => {

  let newLocation =
    '/p/gemeinderatswahlen/' + location.pathname.substring(location.pathname.indexOf('-') + 1, location.pathname.length)
  if (newLocation.includes('einegemeinde')) {
    if (location.search && location.search.startsWith('?gemeinde=')) {
      let value = location.search.substring(location.search.indexOf('=') + 1, location.search.length)
      newLocation += '/' + value
    } else {
      newLocation += '/8'
    }
  }

  return (
    <Async promiseFn={fetchData} location={newLocation} watch={newLocation}>
      {({ data, error, isPending, isFulfilled }) => {
        return (
          <LayoutDefault meta={isFulfilled && data.meta} disable={!layout} {...props}>
            {!isStolApp() && <StyledTitleTopic view={'screen'} title={'Gemeinderatswahlen'}></StyledTitleTopic>}
            {isStolApp() && <StyledTitleTopic2 view={'screen'} title={'Gemeinderatswahlen'}></StyledTitleTopic2>}

            <ScreenCouncilElection
              error={error}
              location={location}
              loading={isPending}
              response={isFulfilled && data}
              {...props}
            />
          </LayoutDefault>
        )
      }}
    </Async>
  )
}

CouncilElection.getInitialData = ({ req }) => {
  return fetchData({ location: '/p/gemeinderatswahlen/' + req.url.substring(req.url.indexOf('-') + 1, req.url.length) })
}

CouncilElection.propTypes = {
  initialData: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    config: PropTypes.object,
  }),
  location: PropTypes.object,
  match: PropTypes.object,
}

CouncilElection.defaultProps = {
  location: {
    pathname: undefined,
  },
  layout: true,
}

export default CouncilElection
