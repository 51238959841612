import React from 'react'
import styled from 'styled-components'
import SkeletonMonitoringData from '../../components/LiveData/components/MonitoringData/skeleton'
import Text from '../../../../components/Text'
import Link from '../../../../components/Link'
import translate from '../../../../modules/translate'
import SkeletonTemperature from '../../components/LiveData/components/Temperature/skeleton'
import TextSkeleton, { Line0TextSkeleton } from '../../../../components/Text/views/TextSkeleton'
import routes from '../../../routes'
import {
  WidgetNearMeWeatherFirstWrapper,
  WidgetNearMeWeatherSecondWrapper,
  WidgetNearMeWeatherStationsWrapper,
  WidgetNearMeWeatherTemperatureWrapper,
} from './index'

const Wrapper = styled.div`
  width: ${(props) => props.theme.sizes.col12};
  display: flex;
  background-color: ${(props) => props.theme.color.background};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-direction: column;
  }
`

const StyledMoreWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: calc(${(props) => props.theme.sizes.gapVertical} / 2);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
  }
`

const MoreText = styled(Text)`
  color: ${(props) => props.theme.color.stol};
  font-size: 15px;
  padding-right: ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapCol};
  }
`

const StyledTitleSkeleton = styled(TextSkeleton)`
  width: 100%;
  display: flex;
  justify-content: center;

  ${Line0TextSkeleton} {
    border-width: 25px;
    width: 100%;
  }
`

const StyledSkeletonMonitoringData = styled(SkeletonMonitoringData)`
  width: 100%;
`

const SkeletonWidgetNearMeWeather = ({ currentCommuneSlug, response, error, loading, ...props }) => (
  <Wrapper {...props}>
    <WidgetNearMeWeatherFirstWrapper>
      <WidgetNearMeWeatherTemperatureWrapper>
        <SkeletonTemperature />
      </WidgetNearMeWeatherTemperatureWrapper>
      <StyledTitleSkeleton lines={1} lastWidth="50%" />
    </WidgetNearMeWeatherFirstWrapper>
    <WidgetNearMeWeatherSecondWrapper>
      <WidgetNearMeWeatherStationsWrapper>
        <StyledSkeletonMonitoringData />
      </WidgetNearMeWeatherStationsWrapper>
      <StyledMoreWrapper>
        <Link to={routes.getOptions('weather').path}>
          <MoreText>{translate('more')}</MoreText>
        </Link>
      </StyledMoreWrapper>
    </WidgetNearMeWeatherSecondWrapper>
  </Wrapper>
)

export default SkeletonWidgetNearMeWeather
