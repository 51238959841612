import React from 'react'
import ElectionScreen from './screens/ElectionScreen'
import MayorScreen from './screens/MayorScreen'
import { namedRoute } from '../../../modules/named-router'
import { matchPath } from 'react-router'
import translate from '../../../modules/translate'
import isServerSide from '../../../modules/is-server-side'
import routes from '../routes'

const errorOptions = {
  component: translate('topic'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'Topic screen display',
}
const ScreenCouncilElection = ({ location, response, loading, failed, error, ...props }) => {
  const value = location ? location.pathname : !isServerSide() && window.location && window.location.pathname
  const pushToErrorScreen = () => {
    props.history.push({ pathname: '/p/error', state: { location: isoLocation } })
  }
  let isoLocation = location ? location : !isServerSide() && window.location
  const data = response && response.data

  if (matchPath(value, "/p/gemeinderatswahlen-einegemeinde")) {
    return <ElectionScreen data={data} loading={loading} location={value} failed={failed} />
  }
  if (matchPath(value, "/p/gemeinderatswahlen-allebuergermeister")) {
    return <MayorScreen data={data} loading={loading} location={value} failed={failed} />
  }
}

ScreenCouncilElection.propTypes = {}
ScreenCouncilElection.defaultProps = {}
export default ScreenCouncilElection
