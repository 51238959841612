import React, { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Temperature, {
  Temperature0StyledIconWeather,
  Temperature0StyledTextTemperature,
  Temperature0ContainerIcon,
} from '../../components/LiveData/components/Temperature'
import MonitoringData from '../../components/LiveData/components/MonitoringData'
import Title from '../../../../components/Title'
import Text from '../../../../components/Text'
import Link from '../../../../components/Link'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import SkeletonWidgetNearMeWeather from './skeleton'
import routes from '../../../routes'
import useSelectStation from '../../modules/use-select-station'
import SelectLocation from '../../../../components/SelectLocation'
import useCookieGeoLocation from '../../../../modules/use-cookie-geo-location'
import useCookieGeoCoordinates from '../../../../modules/use-cookie-geo-coordinates'
import { useUserContext } from '../../../../components/ContextUser'

const Wrapper = styled.div`
  width: ${(props) => props.theme.sizes.col12};
  display: flex;
  background-color: ${(props) => props.theme.color.background};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-direction: column;
  }
`

const FirstWrapper = styled.div`
  border-right: rgba(0, 0, 0, 0.2) 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  padding-right: ${(props) => props.theme.sizes.gapCol};
  padding-left: ${(props) => props.theme.sizes.gapCol};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapCol};
    padding-left: ${(props) => props.theme.tablet.sizes.gapCol};
    border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
    border-right: transparent;
    margin-bottom: 0;
    padding-bottom: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
    margin-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapCol};
    padding-left: ${(props) => props.theme.mobile.sizes.gapCol};
    border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
    margin-bottom: 0;
    padding-bottom: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
  }
`

const SecondWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const StyledTemperature = styled(Temperature)`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${Temperature0ContainerIcon} {
    display: block;
  }

  ${Temperature0StyledTextTemperature} {
    font-size: 45px;
    margin-left: 0px;
    margin-right: 0px;
  }

  ${Temperature0StyledIconWeather} {
    width: 150px;
  }
`

const StyledMonitoringData = styled(MonitoringData)`
  width: 100%;
`

const StyledTitle = styled(Title)`
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  font-weight: normal;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  ${(props) =>
    props.currentCommuneSlug === undefined &&
    css`
      display: none;
    `}
`

const StyledMoreWrapper = styled.div`
  width: 100%;
  text-align: right;
`

const MoreText = styled(Text)`
  color: ${(props) => props.theme.color.stol};
  font-size: 15px;
  padding-right: ${(props) => props.theme.sizes.gapCol};
  margin-bottom: calc(${(props) => props.theme.sizes.gapVertical} / 2);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapCol};
    margin-bottom: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapCol};
    margin-bottom: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
  }
`
const StyledRowLocation = styled(SelectLocation)`
  display: block;
  width: 100%;
`

const StationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: calc(${(props) => props.theme.sizes.gapVertical} / 2);
  margin-right: ${(props) => props.theme.sizes.gapCol};
  margin-left: ${(props) => props.theme.sizes.gapCol};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-right: ${(props) => props.theme.tablet.sizes.gapCol};
    margin-left: ${(props) => props.theme.tablet.sizes.gapCol};
    margin-bottom: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
    margin-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: ${(props) => props.theme.mobile.sizes.gapCol};
    margin-left: ${(props) => props.theme.mobile.sizes.gapCol};
    margin-bottom: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
  }
`

const TemperatureWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
`

const WidgetNearMeWeather = ({ currentCommuneSlug, data, loading, ...props }) => {
  const [isManualSelection, setIsManualSelection] = useState(false)
  const [initialLocation, setInitialLocation] = useState()
  const [initialCoordinates, setInitialCoordinates] = useState()
  const errorOptions = {
    component: translate('nearme_title'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'WidgetNearme index',
  }
  const { onUpdateStation, selectedStations, temperature } = useSelectStation({ data: data.live })
  const { save, cookie: cookieGeoLocation } = useCookieGeoLocation()
  const { cookie: cookieGeoCoordinates } = useCookieGeoCoordinates()
  const { profile } = useUserContext()

  useEffect(() => {
    if (isManualSelection) {
      return
    }
    if (cookieGeoCoordinates) {
      setInitialCoordinates(cookieGeoCoordinates)
      return
    }
    if (cookieGeoLocation) {
      setInitialLocation(cookieGeoLocation)
      return
    }
    if (profile?.user_municipality) {
      const profileCommune = profile?.user_municipality && JSON.parse(profile?.user_municipality)[0]
      setInitialLocation(profileCommune)
      return
    }
  }, [profile, cookieGeoLocation, cookieGeoCoordinates, isManualSelection])
  const onUpdateLocation = ({ selectedLocation }) => {
    setIsManualSelection(true)
    onUpdateStation(selectedLocation)
    save(selectedLocation.location)
  }

  const onStartLocation = ({ selectedLocation }) => {
    onUpdateStation(selectedLocation)
  }

  return (
    <ErrorBoundary {...errorOptions}>
      {loading ? (
        <SkeletonWidgetNearMeWeather />
      ) : data ? (
        <Wrapper {...props}>
          <FirstWrapper>
            <ErrorBoundary {...errorOptions}>
              {loading ? (
                <Fragment />
              ) : data ? (
                <TemperatureWrapper>
                  <StyledTitle tag="h2" currentCommuneSlug={currentCommuneSlug}>
                    {currentCommuneSlug && translate('weather_widget_near_me_title') + ' '}
                    <strong>{currentCommuneSlug && currentCommuneSlug}</strong>
                  </StyledTitle>
                  <StyledTemperature
                    currentCommuneSlug={currentCommuneSlug}
                    weatherCode={data.live.weatherCode}
                    temperature={temperature}
                  />
                </TemperatureWrapper>
              ) : (
                <MessageError logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
              )}
              <StyledRowLocation
                onChange={onUpdateLocation}
                onStart={onStartLocation}
                initialLocation={initialLocation}
                initialCoordinates={initialCoordinates}
              />
            </ErrorBoundary>
          </FirstWrapper>
          <ErrorBoundary {...errorOptions}>
            {loading ? (
              <Fragment />
            ) : data ? (
              <SecondWrapper>
                <StationsWrapper>
                  <StyledMonitoringData currentCommuneSlug={currentCommuneSlug} monitoringData={selectedStations} />
                </StationsWrapper>
                <StyledMoreWrapper>
                  <Link to={routes.getOptions('weather').path}>
                    <MoreText>{translate('more')}</MoreText>
                  </Link>
                </StyledMoreWrapper>
              </SecondWrapper>
            ) : (
              <MessageError logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
            )}
          </ErrorBoundary>
        </Wrapper>
      ) : (
        <MessageError logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
      )}
    </ErrorBoundary>
  )
}

export default WidgetNearMeWeather

export {
  StationsWrapper as WidgetNearMeWeatherStationsWrapper,
  FirstWrapper as WidgetNearMeWeatherFirstWrapper,
  SecondWrapper as WidgetNearMeWeatherSecondWrapper,
  TemperatureWrapper as WidgetNearMeWeatherTemperatureWrapper,
}
