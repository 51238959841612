import RowLocation from '../LiveData/components/RowLocation'
import Temperature from '../LiveData/components/Temperature'
import SunMoon from '../LiveData/components/SunMoon'
import MonitoringData from '../LiveData/components/MonitoringData'
import React from 'react'
import bottomLine from '../../../../modules/mixins/bottom-line'
import styled from 'styled-components'
import useSelectStations from '../../modules/use-select-stations'

const DataWrapper = styled.div`
  flex: 1;
  padding: ${(props) => props.theme.sizes.gapColHalf} 0;
  order: 2;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.tablet.sizes.gapColHalf} 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.mobile.sizes.gapColHalf} 0;
  }
`

const SubWrapper = styled.div`
  margin-left: ${(props) => props.theme.sizes.gapCol};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  position: relative;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.tablet.sizes.gapCol};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapCol};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const FirstWrapper = styled(SubWrapper)`
  padding-bottom: ${(props) => props.theme.sizes.gapColHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapColHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapColHalf};
  }
  ${bottomLine};
`

const DataWeather = ({ data }) => {
  const {
    alphabeticSortedStations,
    selectedStationOption,
    onUpdateStation,
    selectedStations,
    temperature,
  } = useSelectStations({ data })

  return (
    <DataWrapper>
      <FirstWrapper>
        <RowLocation
          values={alphabeticSortedStations}
          value={selectedStationOption}
          onChange={onUpdateStation}
          placeholder={'select_weather_station_selection'}
        />
        <Temperature
          temperature={temperature && temperature.toLocaleString('de-DE', { maximumFractionDigits: 2 })}
          min={data && data.min.toString()}
          max={data && data.max.toString()}
        />
        <SunMoon sunRise={data.sunRise} sunSet={data.sunSet} moonRise={data.moonRise} moonSet={data.moonSet} />
      </FirstWrapper>
      <SubWrapper>
        <MonitoringData monitoringData={selectedStations} />
      </SubWrapper>
    </DataWrapper>
  )
}

export default DataWeather

export { FirstWrapper as LiveData0FirstWrapper, SubWrapper as LiveData0SubWrapper, DataWrapper as LiveData0DataWrapper }
