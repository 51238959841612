const communes = () => {
  const list = [
    'Abtei (Abtei)',
    'Abtei (St.Kassian)',
    'Abtei (Stern)',
    'Ahrntal (Luttach)',
    'Ahrntal (St.Jakob)',
    'Ahrntal (St.Johann)',
    'Ahrntal (St.Peter)',
    'Ahrntal (Weißenbach)',
    'Aldein (Aldein)',
    'Aldein (Radein)',
    'Algund (Aschbach / Ried)',
    'Algund (Dorf)',
    'Algund (Forst)',
    'Algund (Mitterplars)',
    'Algund (Mühlbach)',
    'Algund (Oberplars)',
    'Algund (Vellau)',
    'Altrei (Altrei)',
    'Altrei (Eben)',
    'Altrei (Guggal)',
    'Andrian (Andrian)',
    'Auer (Auer)',
    'Barbian (Hauptort)',
    'Barbian (Kollmann)',
    'Bozen (Bozen)',
    'Bozen (Don Bosco)',
    'Bozen (Europa - Neustift)',
    'Bozen (Gries - Quirein)',
    'Bozen (Oberau - Haslach)',
    'Bozen (Zentrum - Bozner Boden - Rentsch)',
    'Branzoll (Branzoll)',
    'Brenner (Brenner)',
    'Brenner (Brennerbad)',
    'Brenner (Giggelberg)',
    'Brenner (Gossensaß)',
    'Brenner (Pflersch)',
    'Brenner (Pontigl)',
    'Brixen (Afers)',
    'Brixen (Albeins)',
    'Brixen (Gereuth)',
    'Brixen (Hauptort)',
    'Brixen (Karnol)',
    'Brixen (Klerant)',
    'Brixen (Kranebitt)',
    'Brixen (Mahr)',
    'Brixen (Mairdorf)',
    'Brixen (Mellaun)',
    'Brixen (Pairdorf)',
    'Brixen (Pinzagen)',
    'Brixen (Plabach)',
    'Brixen (Rutzenberg)',
    'Brixen (Sarns)',
    'Brixen (St.Leonhard)',
    'Brixen (Tils)',
    'Brixen (Tötschling)',
    'Brixen (Tschötsch)',
    'Brixen (Untereben)',
    'Bruneck (Aufhofen)',
    'Bruneck (Dietenheim)',
    'Bruneck (Hauptort)',
    'Bruneck (Reischach)',
    'Bruneck (St.Georgen)',
    'Bruneck (Stegen)',
    'Burgstall (Burgstall)',
    'Corvara (Corvara)',
    'Corvara (Kolfuschg)',
    'Corvara (Pescosta)',
    'Deutschnofen (Deutschnofen)',
    'Deutschnofen (Eggen)',
    'Deutschnofen (Petersberg)',
    'Enneberg (Enneberg)',
    'Enneberg (St.Vigil)',
    'Enneberg (Welschellen)',
    'Eppan a.d.Weinstr. (Berg)',
    'Eppan a.d.Weinstr. (Frangart)',
    'Eppan a.d.Weinstr. (Gaid)',
    'Eppan a.d.Weinstr. (Gand)',
    'Eppan a.d.Weinstr. (Girlan)',
    'Eppan a.d.Weinstr. (Missian)',
    'Eppan a.d.Weinstr. (Montiggl)',
    'Eppan a.d.Weinstr. (Predonig)',
    'Eppan a.d.Weinstr. (St.Michael)',
    'Eppan a.d.Weinstr. (St.Pauls)',
    'Eppan a.d.Weinstr. (Unterrain)',
    'Feldthurns (Dorf)',
    'Feldthurns (Garn)',
    'Feldthurns (Guln)',
    'Feldthurns (Pedratz)',
    'Feldthurns (Schnauders)',
    'Feldthurns (Schrambach)',
    'Feldthurns (Tschiffnon)',
    'Feldthurns (Untrum)',
    'Franzensfeste (Franzensfeste)',
    'Franzensfeste (Grasstein)',
    'Franzensfeste (Mittewald)',
    'Freienfeld (Egg)',
    'Freienfeld (Elzenbaum)',
    'Freienfeld (Flains)',
    'Freienfeld (Flans)',
    'Freienfeld (Gschliess)',
    'Freienfeld (Gupp)',
    'Freienfeld (Leimgruben)',
    'Freienfeld (Mauls)',
    'Freienfeld (Niederried)',
    'Freienfeld (Partinges)',
    'Freienfeld (Pfulters)',
    'Freienfeld (Ritzail)',
    'Freienfeld (Rust)',
    'Freienfeld (Schaitach)',
    'Freienfeld (Sprechenstein)',
    'Freienfeld (Stilfes)',
    'Freienfeld (Trens)',
    'Freienfeld (Valgenäun)',
    'Gais (Gais)',
    'Gais (Lanebach)',
    'Gais (Mühlbach)',
    'Gais (Tesselberg)',
    'Gais (Uttenheim)',
    'Gargazon (Gargazon)',
    'Glurns (Glurns)',
    'Graun im Vinschgau (Graun)',
    'Graun im Vinschgau (Langtaufers)',
    'Graun im Vinschgau (Reschen)',
    'Graun im Vinschgau (St.Valentin auf der Haide)',
    'Gsies (Ausserpichl)',
    'Gsies (Innerpichl)',
    'Gsies (Oberplanken)',
    'Gsies (St.Magdalena Niedertal)',
    'Gsies (St.Magdalena Obertal)',
    'Gsies (St.Martin Niedertal)',
    'Gsies (St.Martin Obertal)',
    'Gsies (Unterplanken)',
    'Hafling (Hafling)',
    'Innichen (Hauptort)',
    'Innichen (Innichberg)',
    'Innichen (Vierschach)',
    'Innichen (Winnebach)',
    'Jenesien (Afing)',
    'Jenesien (Flaas)',
    'Jenesien (Glaning)',
    'Jenesien (Hauptort)',
    'Jenesien (Nobls)',
    'Kaltern a.d.Weinstr. (Altenburg)',
    'Kaltern a.d.Weinstr. (Kalterer Moos)',
    'Kaltern a.d.Weinstr. (Kaltern a.d.W.)',
    'Kaltern a.d.Weinstr. (Klughammer)',
    'Kaltern a.d.Weinstr. (Mitterdorf)',
    'Kaltern a.d.Weinstr. (Oberplanitzing)',
    'Kaltern a.d.Weinstr. (Pfuß)',
    'Kaltern a.d.Weinstr. (St. Anton)',
    'Kaltern a.d.Weinstr. (St. Nikolaus)',
    'Kaltern a.d.Weinstr. (St.Josef am See)',
    'Kaltern a.d.Weinstr. (Unterplanitzing)',
    'Karneid (Blumau)',
    'Karneid (Breien)',
    'Karneid (Gummer)',
    'Karneid (Kardaun)',
    'Karneid (Karneid)',
    'Karneid (Steinegg)',
    'Kastelbell-Tschars (Freiberg)',
    'Kastelbell-Tschars (Galsaun)',
    'Kastelbell-Tschars (Juval)',
    'Kastelbell-Tschars (Kastelbell)',
    'Kastelbell-Tschars (Latschinig)',
    'Kastelbell-Tschars (Marein)',
    'Kastelbell-Tschars (Tomberg)',
    'Kastelbell-Tschars (Trumsberg)',
    'Kastelbell-Tschars (Tschars)',
    'Kastelruth (Kastelruth)',
    'Kastelruth (Pufels)',
    'Kastelruth (Runggaditsch)',
    'Kastelruth (Seis)',
    'Kastelruth (Seiseralm)',
    'Kastelruth (St.Michael)',
    'Kastelruth (St.Oswald)',
    'Kastelruth (St.Valentin)',
    'Kastelruth (St.Vigil)',
    'Kastelruth (Tagusens)',
    'Kastelruth (Tisens)',
    'Kastelruth (Überwasser)',
    'Kiens (Ehrenburg)',
    'Kiens (Getzenberg)',
    'Kiens (Hofern)',
    'Kiens (Kiens)',
    'Kiens (St.Sigmund)',
    'Klausen (Frag)',
    'Klausen (Griesbruck / Seebegg)',
    'Klausen (Gufidaun)',
    'Klausen (Hauptort)',
    'Klausen (Latzfons)',
    'Klausen (St. Josef / Leitach)',
    'Klausen (Verdings)',
    'Kuens (Kuens)',
    'Kurtatsch a.d.Weinstr. (Entiklar)',
    'Kurtatsch a.d.Weinstr. (Graun)',
    'Kurtatsch a.d.Weinstr. (Kurtatsch a.d.W.)',
    'Kurtatsch a.d.Weinstr. (Penon)',
    'Kurtinig a.d.Weinstr. (Kurtinig a.d.W.)',
    'Laas (Allitz)',
    'Laas (Eyrs)',
    'Laas (Laas)',
    'Laas (Parnetz)',
    'Laas (Tanas)',
    'Laas (Tarnell)',
    'Laas (Tschengls)',
    'Lajen (Albions)',
    'Lajen (Freins)',
    'Lajen (Hauptort)',
    'Lajen (Ried)',
    'Lajen (St.Peter)',
    'Lajen (Tanirz)',
    'Lajen (Tschöfas)',
    'Lana (Lana)',
    'Lana (Pawigl)',
    'Lana (Völlan)',
    'Latsch (Goldrain)',
    'Latsch (Latsch / St.Martin im Kofl)',
    'Latsch (Morter)',
    'Latsch (Tarsch)',
    'Laurein (Laurein)',
    'Leifers (Leifers)',
    'Leifers (Seit)',
    'Leifers (St.Jakob)',
    'Leifers (Steinmannwald)',
    'Lüsen (Berg)',
    'Lüsen (Flitt)',
    'Lüsen (Huben)',
    'Lüsen (Kreuz)',
    'Lüsen (Lüsen)',
    'Lüsen (Petschied)',
    'Lüsen (Rungg)',
    'Mals (Burgeis)',
    'Mals (Laatsch)',
    'Mals (Mals)',
    'Mals (Matsch)',
    'Mals (Planeil)',
    'Mals (Schleis)',
    'Mals (Schlinig)',
    'Mals (Tartsch)',
    'Mals (Ulten-Alsack)',
    'Margreid a.d.Weinstr. (Margreid a.d.W.)',
    'Margreid a.d.Weinstr. (Unterfennberg)',
    'Marling (Marling)',
    'Martell (Ennetal)',
    'Martell (Ennewasser)',
    'Martell (Gand)',
    'Martell (Hintermartell)',
    'Martell (Meiern)',
    'Martell (Sonnenberg)',
    'Martell (Waldberg)',
    'Meran (Gampenstraße)',
    'Meran (Gratsch)',
    'Meran (Mais)',
    'Meran (Maria Himmelfahrt)',
    'Meran (Marlinger Siedlung)',
    'Meran (Meran Historischer Ortskern)',
    'Meran (Meran Zentrum)',
    'Meran (Meran)',
    'Meran (Musiker-Viertel)',
    'Meran (Obermais)',
    'Meran (Sinich)',
    'Meran (St. Valentin)',
    'Meran (St.-Vigil-Platz)',
    'Meran (Steinach)',
    'Meran (Wolkensteinstraße)',
    'Mölten (Hauptort)',
    'Mölten (Schlaneid)',
    'Mölten (Verschneid)',
    'Mölten (Versein)',
    'Montan a.d.Weinstr. (Glen)',
    'Montan a.d.Weinstr. (Gschnon)',
    'Montan a.d.Weinstr. (Kalditsch)',
    'Montan a.d.Weinstr. (Kaltenbrunn)',
    'Montan a.d.Weinstr. (Montan)',
    'Montan a.d.Weinstr. (Pinzon)',
    'Moos in Passeier (Moos in Passeier)',
    'Moos in Passeier (Pfelders)',
    'Moos in Passeier (Platt)',
    'Moos in Passeier (Rabenstein)',
    'Moos in Passeier (Stuls)',
    'Mühlbach (Hauptort)',
    'Mühlbach (Meransen)',
    'Mühlbach (Spinges)',
    'Mühlbach (Vals)',
    'Mühlwald (Außermühlwald)',
    'Mühlwald (Hauptort)',
    'Mühlwald (Lappach)',
    'Nals (Nals)',
    'Naturns (Naturns)',
    'Naturns (Staben)',
    'Naturns (Tabland)',
    'Naturns (Tschirland)',
    'Natz-Schabs (Aicha)',
    'Natz-Schabs (Natz)',
    'Natz-Schabs (Raas)',
    'Natz-Schabs (Viums)',
    'Neumarkt (Laag)',
    'Neumarkt (Mazon)',
    'Neumarkt (Neumarkt)',
    'Neumarkt (Vill)',
    'Niederdorf (Niederdorf)',
    'Olang (Geiselsberg)',
    'Olang (Mitterolang)',
    'Olang (Niederolang)',
    'Olang (Oberolang)',
    'Partschins (Partschins)',
    'Partschins (Quadrat)',
    'Partschins (Rabland)',
    'Partschins (Sonnenberg)',
    'Partschins (Tabland)',
    'Partschins (Töll)',
    'Partschins (Vertigen)',
    'Percha (Aschbach)',
    'Percha (Litschbach)',
    'Percha (Nasen)',
    'Percha (Oberwielenbach)',
    'Percha (Percha)',
    'Percha (Platten)',
    'Percha (Unterwielenbach)',
    'Percha (Wielenberg)',
    'Pfalzen (Greinwalden)',
    'Pfalzen (Hauptort)',
    'Pfalzen (Issing)',
    'Pfatten (Birti)',
    'Pfatten (Carnel)',
    'Pfatten (Frizzi-Au)',
    'Pfatten (Gmund)',
    'Pfatten (Hirschen)',
    'Pfatten (Keller)',
    'Pfatten (Klughammer)',
    'Pfatten (Mairhof)',
    'Pfatten (Pfatten Dorf)',
    'Pfatten (Piglon)',
    'Pfatten (Stadel-Laimburg)',
    'Pfatten (Wachsbleiche)',
    'Pfitsch (Kematen)',
    'Pfitsch (St.Jakob / Innerpfitsch)',
    'Pfitsch (Wiesen / Außerpfitsch)',
    'Plaus (Plaus)',
    'Prad am Stilfser Joch (Lichtenberg)',
    'Prad am Stilfser Joch (Prad)',
    'Prags (Ausserprags)',
    'Prags (Innerprags)',
    'Prags (St.Veit)',
    'Prettau (Prettau)',
    'Proveis (Proveis)',
    'Rasen-Antholz (Antholz-Mittertal)',
    'Rasen-Antholz (Antholz-Niedertal)',
    'Rasen-Antholz (Antholz-Obertal)',
    'Rasen-Antholz (Neunhäusern)',
    'Rasen-Antholz (Niederrasen)',
    'Rasen-Antholz (Oberrasen)',
    'Ratschings (Ausserratschings)',
    'Ratschings (Gasteig)',
    'Ratschings (Innerratschings)',
    'Ratschings (Jaufental)',
    'Ratschings (Mareit)',
    'Ratschings (Ridnaun)',
    'Ratschings (Telfes)',
    'Riffian (Gfeis)',
    'Riffian (Magdfeld)',
    'Riffian (Riffian)',
    'Riffian (Vernuer)',
    'Ritten (Atzwang)',
    'Ritten (Gissmann)',
    'Ritten (Klobenstein)',
    'Ritten (Lengmoos)',
    'Ritten (Lengstein)',
    'Ritten (Mittelberg)',
    'Ritten (Oberbozen)',
    'Ritten (Oberinn)',
    'Ritten (Signat)',
    'Ritten (Sill)',
    'Ritten (Unterinn)',
    'Ritten (Wangen)',
    'Rodeneck (Ahnerberg)',
    'Rodeneck (Frelerberg)',
    'Rodeneck (Gifen)',
    'Rodeneck (Nauders)',
    'Rodeneck (Spisses)',
    'Rodeneck (St.Pauls)',
    'Rodeneck (Vill)',
    'Salurn a.d. Weinstr. (Buchholz)',
    'Salurn a.d. Weinstr. (Gfrill)',
    'Salurn a.d. Weinstr. (Salurn)',
    'Sand in Taufers (Ahornach)',
    'Sand in Taufers (Kematen)',
    'Sand in Taufers (Mühlen in Taufers)',
    'Sand in Taufers (Rein in Taufers)',
    'Sand in Taufers (Sand in Taufers)',
    'Sarntal (Aberstückl)',
    'Sarntal (Agratsberg)',
    'Sarntal (Astfeld)',
    'Sarntal (Auen)',
    'Sarntal (Ausserpens)',
    'Sarntal (Dick)',
    'Sarntal (Durnholz)',
    'Sarntal (Essenberg)',
    'Sarntal (Gebracksberg)',
    'Sarntal (Glern)',
    'Sarntal (Innerpens)',
    'Sarntal (Kandelsberg)',
    'Sarntal (Muls)',
    'Sarntal (Niederwangen)',
    'Sarntal (Nordheim)',
    'Sarntal (Öttenbach)',
    'Sarntal (Putzen)',
    'Sarntal (Reinswald)',
    'Sarntal (Riedelsberg)',
    'Sarntal (Rungg)',
    'Sarntal (Sarnthein)',
    'Sarntal (Steet)',
    'Sarntal (Trienbach)',
    'Sarntal (Unterreinswald)',
    'Sarntal (Vormeswald)',
    'Sarntal (Weissenbach)',
    'Sarntal (Windlahn)',
    'Schenna (Schenna)',
    'Schenna (Schennaberg)',
    'Schenna (Tall)',
    'Schenna (Verdins)',
    'Schlanders (Göflan)',
    'Schlanders (Kortsch)',
    'Schlanders (Nördersberg)',
    'Schlanders (Schlanders)',
    'Schlanders (Sonnenberg)',
    'Schlanders (Vetzan)',
    'Schluderns (Schluderns)',
    'Schnals (Karthaus)',
    'Schnals (Katharinaberg)',
    'Schnals (Kurzras)',
    'Schnals (Unser Frau)',
    'Schnals (Vernagt)',
    'Sexten (Außerbauerschaft)',
    'Sexten (Außerberg)',
    'Sexten (Kiniger)',
    'Sexten (Mitterberg)',
    'Sexten (Moos)',
    'Sexten (Schmieden)',
    'Sexten (St.Veit)',
    'St. Christina in Gröden (St.Christina in Gröden)',
    'St. Leonhard in Pass. (Schweinsteg)',
    'St. Leonhard in Pass. (St.Leonhard i.P.)',
    'St. Leonhard in Pass. (Walten)',
    'St. Lorenzen (Ellen)',
    'St. Lorenzen (Fassing)',
    'St. Lorenzen (Hauptort)',
    'St. Lorenzen (Hörschwang)',
    'St. Lorenzen (Kniepass)',
    'St. Lorenzen (Lothen)',
    'St. Lorenzen (Montal)',
    'St. Lorenzen (Moos)',
    'St. Lorenzen (Onach)',
    'St. Lorenzen (Pflaurenz)',
    'St. Lorenzen (Runggen)',
    'St. Lorenzen (Saalen)',
    'St. Lorenzen (Sonnenburg)',
    'St. Lorenzen (St.Martin)',
    'St. Lorenzen (Stefansdorf)',
    'St. Pankraz (St.Pankraz)',
    'St. Ulrich (St.Ulrich)',
    'St.Martin in Passeier (Christl)',
    'St.Martin in Passeier (Flon)',
    'St.Martin in Passeier (Kalmtal)',
    'St.Martin in Passeier (Matatz)',
    'St.Martin in Passeier (Quellenhof)',
    'St.Martin in Passeier (Ried)',
    'St.Martin in Passeier (Saltaus)',
    'St.Martin in Passeier (St.Martin i.P.)',
    'St.Martin in Thurn (Campill)',
    'St.Martin in Thurn (Pikolein)',
    'St.Martin in Thurn (St.Martin in Thurn)',
    'St.Martin in Thurn (Untermoj)',
    'Sterzing (Ried)',
    'Sterzing (Sterzing)',
    'Sterzing (Thuins)',
    'Sterzing (Tschöfs)',
    'Stilfs (Außersulden)',
    'Stilfs (Gomagoi)',
    'Stilfs (Stilfs Dorf)',
    'Stilfs (Stilfs Höfe)',
    'Stilfs (Stilfserbrücke)',
    'Stilfs (Sulden)',
    'Stilfs (Trafoi)',
    'Taufers im Münstertal (Rifair)',
    'Taufers im Münstertal (Taufers i.M.)',
    'Terenten (Ast)',
    'Terenten (Hohenbühl)',
    'Terenten (Holderloch)',
    'Terenten (Lehen)',
    'Terenten (Margen)',
    'Terenten (Pein)',
    'Terenten (Pichlern)',
    'Terenten (Schneeberg)',
    'Terenten (Sonnberg)',
    'Terenten (Talson)',
    'Terlan (Siebeneich)',
    'Terlan (Terlan)',
    'Terlan (Vilpian)',
    'Tiers (Tiers)',
    'Tirol (Tirol)',
    'Tisens (Gfrill)',
    'Tisens (Grissian)',
    'Tisens (Naraun)',
    'Tisens (Platzers)',
    'Tisens (Prissian)',
    'Tisens (Schernag)',
    'Tisens (Tisens)',
    'Toblach (Aufkirchen)',
    'Toblach (Hauptort)',
    'Toblach (Wahlen)',
    'Tramin a.d. Weinstr. (Tramin)',
    'Truden im Naturpark (Kaltenbrunn)',
    'Truden im Naturpark (Mühlen)',
    'Truden im Naturpark (San Lugano)',
    'Truden im Naturpark (Truden)',
    'Tscherms (Tscherms)',
    'Ulten (St.Gertraud Kuppelwies)',
    'Ulten (St.Gertraud)',
    'Ulten (St.Nikolaus)',
    'Ulten (St.Walburg)',
    'Unsere Liebe Frau i.W.-St.Felix (St.Felix)',
    'Unsere Liebe Frau i.W.-St.Felix (U.L. Frau i.W.)',
    'Vahrn (Hauptort)',
    'Vahrn (Neustift)',
    'Vahrn (Schalders)',
    'Vahrn (Spiluck)',
    'Villanders (St.Moritz)',
    'Villanders (St.Stefan)',
    'Villanders (St.Valentin)',
    'Villnöss (Coll)',
    'Villnöss (St.Jakob)',
    'Villnöss (St.Magdalena)',
    'Villnöss (St.Peter)',
    'Villnöss (St.Valentin)',
    'Villnöss (Teis)',
    'Vintl (Niedervintl)',
    'Vintl (Obervintl)',
    'Vintl (Pfunders)',
    'Vintl (Weitental)',
    'Völs am Schlern (Blumau)',
    'Völs am Schlern (Oberaicha)',
    'Völs am Schlern (Obervöls)',
    'Völs am Schlern (Peterbühl)',
    'Völs am Schlern (Prösels)',
    'Völs am Schlern (Pröslerried)',
    'Völs am Schlern (St.Anton)',
    'Völs am Schlern (St.Kathrein)',
    'Völs am Schlern (St.Konstantin)',
    'Völs am Schlern (Steg)',
    'Völs am Schlern (Ums)',
    'Völs am Schlern (Unteraicha)',
    'Völs am Schlern (Untervöls)',
    'Völs am Schlern (Völserried)',
    'Vöran (Aschl)',
    'Vöran (Vöran)',
    'Waidbruck (Waidbruck)',
    'Welsberg-Taisten (Taisten)',
    'Welsberg-Taisten (Welsberg)',
    'Welschnofen (Welschnofen)',
    'Wengen (Wengen)',
    'Wolkenstein in Gröden (Wolkenstein)',
  ]

  const labelToValue = (label) => {
    return label.toLowerCase().replace(/\s+/g, '-')
  }

  const selectable = list.map((commune) => {
    return {
      label: commune,
      value: labelToValue(commune),
    }
  })

  const labelToSelected = (label) => {
    return {
      label,
      value: labelToValue(label),
    }
  }

  const getCommune = (label) => {
    return label.replace(/\(.*?\)/g, '')
  }

  const getSubLocation = (label) => {
    const match = label.match(/\((.*?)\)/)
    if (match) {
      return match[1]
    }
  }

  return { selectable, list, labelToValue, labelToSelected, getCommune, getSubLocation }
}

export default communes()
