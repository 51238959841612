import isStolApp from '../is-stolapp'
import queryString from 'query-string'
import isServerSide from '../is-server-side'

const addSpecialTargets = (targets = {}) => {
  if (!isServerSide()) {
    if (isStolApp()) {
      const appInfo = window.athesia_react ? window.athesia_react.app_os + ' ' + window.athesia_react.app_ver : ' '
      targets['STOL7_app'] = 'ja'
      targets['STOL7_app_version'] = appInfo
    } else {
      targets['STOL7_app'] = 'nein'
    }
    const urlQuery = queryString.parse(window.location.search)
    if (urlQuery.dlabAdsTest === 'on') window.localStorage.setItem('dlabAdsTest', 'on')
    if (urlQuery.dlabAdsTest === 'off') window.localStorage.removeItem('dlabAdsTest')
    if (
      (urlQuery.dlabAdsTest !== undefined && urlQuery.dlabAdsTest !== 'off') ||
      window.localStorage.getItem('dlabAdsTest')
    ) {
      targets['test'] = '1'
    }
    if (window.localStorage.getItem('dlabAdsTypeTest')) {
      targets['testType'] = window.localStorage.getItem('dlabAdsTypeTest')
    }
  }
}

export { addSpecialTargets }
