import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import translate from '../../../../../../../../modules/translate'

const Table = styled.div`
  display: table;
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textSecondary};
  width: ${(props) => (props.isHeadRow ? props.theme.sizes.col8 : 'auto')};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => (props.isHeadRow ? props.theme.tablet.sizes.col8 : 'auto')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => (props.isHeadRow ? props.theme.mobile.sizes.col8 : 'auto')};
  }
`
const TableHead = styled.div`
  display: table-header-group;
`
const TableBody = styled.div`
  display: table-row-group;
`
const TableRow = styled.div`
  display: table-row;
`
const HeadCol = styled.div`
  display: table-cell;
  height: 30px;
  width: 130px;
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  font-weight: ${(props) => props.theme.fontWeight.regular};
  text-align: right;
  vertical-align: bottom;
`
const HeadRow = styled.div`
  display: table-cell;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: ${(props) => props.theme.fontSize.text};
  width: 200px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  text-align: left;
`
const Cell = styled.div`
  display: table-cell;
  text-align: right;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.textPrimary};
  line-height: ${(props) => props.theme.lineHeight.text};
`

const TableStation = ({ stationData, isHeadRow, slim, ...props }) => (
  <Table {...{ isHeadRow }} {...props}>
    {!slim && (
      <TableHead>
        <TableRow>
          {isHeadRow && <HeadRow />}
          <HeadCol>
            <strong>{stationData.name}</strong> ({stationData.gps.alt + ' m'})
          </HeadCol>
        </TableRow>
      </TableHead>
    )}
    <TableBody>
      <TableRow>
        {isHeadRow && <HeadRow>{translate('weather_temperature')}</HeadRow>}
        <Cell>{stationData.temperature.toLocaleString('de-DE', { maximumFractionDigits: 2 }) + '°C' || '-'}</Cell>
      </TableRow>
      <TableRow>
        {isHeadRow && <HeadRow>{translate('weather_rainfall')}</HeadRow>}
        <Cell>{stationData.rain + 'mm' || '-'}</Cell>
      </TableRow>
      <TableRow>
        {isHeadRow && <HeadRow>{translate('weather_wind')}</HeadRow>}
        <Cell>
          {stationData.windSpeed || '-'} / {stationData.windGust + ' km/h' || '-'}
        </Cell>
      </TableRow>
      <TableRow>
        {isHeadRow && <HeadRow>{translate('weather_wind_direction')}</HeadRow>}
        <Cell>{stationData.windDirection || '-'}</Cell>
      </TableRow>
      <TableRow>
        {isHeadRow && <HeadRow>{translate('weather_humidity')}</HeadRow>}
        <Cell>{stationData.humidity || '-'}</Cell>
      </TableRow>
      {/*
      <TableRow>
        {isHeadRow && <HeadRow>{translate('weather_temperature_felt')}</HeadRow>}
        <Cell>
          {stationData.heatIndex.toLocaleString('de-DE', { maximumFractionDigits: 2 }) + '°C' ||
            stationData.windChill.toLocaleString('de-DE', { maximumFractionDigits: 2 }) + '°C' ||
            '-'}
        </Cell>
      </TableRow>
        */}
    </TableBody>
  </Table>
)

TableStation.propTypes = {
  stationData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    gps: PropTypes.object.isRequired,
    rain: PropTypes.number,
    windSpeed: PropTypes.number,
    windGust: PropTypes.number,
    windDirection: PropTypes.string,
    humidity: PropTypes.string,
    temperature: PropTypes.number,
  }),
  isHeadRow: PropTypes.bool,
}

TableStation.defaultProps = {
  isHeadRow: true,
}

export default TableStation
