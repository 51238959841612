import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Link from '../../../../components/Link'
import styled from 'styled-components'

const SMARTAD = {
  always: 'always',
  smart: 'smart',
  disabled: 'disabled',
}

const MarginWrapper = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

class ArticleSmartAd {
  constructor(adComponents) {
    this.hasAd = false
    this.hasMobileAd = false
    this.adComponents = adComponents
    this.foundEnd = false
  }

  nodeLength(node, length) {
    if (!this.foundEnd) {
      if (node && (node.name === 'embed' || node.name === 'div' || node.name === 'video-jw')) {
        this.foundEnd = true
        return length
      }
      if (node && node.name === 'br') length += 50
      if (node && node.data) {
        length += node.data.length
      }
      if (node && node.children) {
        //eslint-disable-next-line
        //node.children.map((node) => {
        length = this.nodeLength(node.children[0], length)
        //})
      }
      if (node && node.next) {
        length = this.nodeLength(node.next, length)
      }
    }
    return length
  }

  showDesktopAd({ domNode }) {
    const { type, data, prev, next, name, parent } = domNode
    if (
      ((type === 'text' && data.length > 0 && data !== '\n') ||
        (type === 'tag' && name === 'br') ||
        (type === 'tag' && name === 'a' && prev === null) ||
        (type === 'tag' && name === 'sportnews')) &&
      !this.hasAd &&
      this.adComponents &&
      this.adComponents.showAds &&
      this.adComponents.smartAd !== SMARTAD.disabled
    ) {
      const dataLength = this.nodeLength(domNode, 0)
      this.foundEnd = false
      const prevHeadline = prev && prev.name === 'embed' && prev.attribs.id.includes('headline')
      const isEndOfParagraph = type === 'tag' && name === 'br' && prev && prev.type === 'text'
      const isEndOfArticle = !next && !parent
      if ((!prevHeadline && dataLength > 700) || this.adComponents.smartAd === SMARTAD.always) {
        this.hasAd = true
        return () => (
          <Fragment>
            <MarginWrapper>{this.adComponents.desktop}</MarginWrapper>
            {type === 'tag' && name === 'a' && domNode.children && domNode.attribs.href && domNode.children.length > 0 && (
              <Link autoExternalLinkIcon to={domNode.attribs.href}>
                {domNode.children[0].data}
              </Link>
            )}
            {data}
          </Fragment>
        )
      } else if (isEndOfParagraph || isEndOfArticle) {
        this.hasAd = true
        this.hasMobileAd = true
        return () => (
          <Fragment>
            {data}
            <MarginWrapper>
              {this.adComponents.desktopCentered}
              {this.adComponents.mobile}
            </MarginWrapper>
          </Fragment>
        )
      } else {
        return () => <Fragment>{data}</Fragment>
      }
    }
    return false
  }

  showMobileAd({ domNode, show = false }) {
    if (
      this.adComponents &&
      this.adComponents.showAds &&
      !this.hasMobileAd &&
      this.adComponents.smartAd !== SMARTAD.disabled
    ) {
      const { prev } = domNode
      if (
        (prev && prev.type === 'text' && prev.data.length > 1) ||
        show ||
        this.adComponents.smartAd === SMARTAD.always
      ) {
        this.hasMobileAd = true
        return () => <Fragment>{this.adComponents.mobile}</Fragment>
      }
      return false
    }
    return false
  }
}

ArticleSmartAd.propTypes = {
  adComponents: PropTypes.shape({
    showAds: PropTypes.bool,
    mobile: PropTypes.node,
    desktop: PropTypes.node,
    smartAd: PropTypes.oneOf(Object.values(SMARTAD)),
  }),
}

export default ArticleSmartAd
export { SMARTAD }
