import { useEffect, useState } from 'react'
import translate from '../../../../modules/translate'
import { clientCoordinates, isLocationInSouthTyrol } from '../../../../modules/location'

const geolib = require('geolib')

const useSelectStations = ({ data }) => {
  const [selectedStations, setSelectedStations] = useState([])
  const [selectedStationOption, setSelectedStationOption] = useState(undefined)
  const [temperature, setTemperature] = useState('')

  useEffect(() => {
    findNearest()
  }, [])

  const returnWeatherOption = (station) => {
    if (station && station.gps) {
      return { value: station.id, label: station.name + ' (' + station.gps.alt + ' m)' }
    }
  }
  const onUpdateStation = (selectedStation) => {
    if (selectedStation.value === 0) {
      findNearest()
    } else {
      data &&
        data.stationsData.map((station) => {
          if (selectedStation.value === station.id) {
            setSelectedStations([station])
            setSelectedStationOption(returnWeatherOption(selectedStation))
            setTemperature(station.temperature)
          }
          return null
        })
    }
  }

  const setDefaultStation = () => {
    const defaultStation = data.stationsData.find((station) => station.default && station.default === true)
    if (defaultStation !== undefined) {
      setSelectedStations([defaultStation])
      setSelectedStationOption(returnWeatherOption(defaultStation))
      setTemperature(defaultStation.temperature)
    }
  }

  let selectableStations = []
  data &&
    data.stationsData.map((station) => {
      //setting default station
      if (station.default && station.default === true) {
        if (selectedStations.length <= 0 && selectedStationOption === undefined) {
          setSelectedStations([station])
          setSelectedStationOption(returnWeatherOption(station))
          setTemperature(station.temperature)
        }
      }
      if (station.category === 1 || station.category === 3) {
        selectableStations.push(returnWeatherOption(station))
      }
      /* TODO add Pegelastationen
  if(station.category === 2){
    selectableStations.push({value: station.id, label: station.name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ').replace('Bei', 'bei') + ' ('+station.gps.alt+ ' m) - Pegelstation'})
    helperVarToFindNearestWaterLevelStation[station.id] = {latitude: parseFloat(station.gps.lat.replace(",", ".")), longitude: parseFloat(station.gps.lng.replace(",", "."))}
  }
  */
      return null
    })
  let alphabeticSortedStations = selectableStations.sort((previousValue, nextValue) => {
    if (previousValue.label < nextValue.label) {
      return -1
    }
    if (previousValue.label > nextValue.label) {
      return 1
    }
    return 0
  })
  alphabeticSortedStations.unshift({ value: 0, label: translate('nearme_title') })

  const findNearest = () => {
    clientCoordinates()
      .then((coordinates) => {
        if (isLocationInSouthTyrol(coordinates.latitude, coordinates.longitude)) {
          let justLatLongValues = { latitude: coordinates.latitude, longitude: coordinates.longitude }
          let minDistanceFinderHelper
          let nearestStation
          data &&
            data.stationsData.map((station) => {
              if (station.category === 1 || station.category === 3) {
                let latLongStation = {
                  latitude: parseFloat(station.gps.lat.replace(',', '.')),
                  longitude: parseFloat(station.gps.lng.replace(',', '.')),
                }
                if (minDistanceFinderHelper === undefined) {
                  minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
                  nearestStation = station
                }
                if (geolib && minDistanceFinderHelper > geolib.getDistance(justLatLongValues, latLongStation)) {
                  minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
                  nearestStation = station
                }
              }
              return null
            })
          if (nearestStation !== undefined) {
            setSelectedStations([nearestStation])
            setSelectedStationOption(returnWeatherOption(nearestStation))
            setTemperature(nearestStation.temperature)
          }
        } else {
          setDefaultStation()
        }
      })
      .catch(() => {
        setDefaultStation()
      })
  }
  return {
    alphabeticSortedStations,
    selectedStationOption,
    onUpdateStation,
    selectedStations,
    temperature,
  }
}

export default useSelectStations
