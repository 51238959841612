import { useState } from 'react'
import consoleLogger from '../../../../modules/console-logger'
import { isLocationInSouthTyrol } from '../../../../modules/location'

const geolib = require('geolib')

const useSelectStation = ({ data }) => {
  const defaultStation = data?.stationsData?.find((station) => station.default && station.default === true)
  const [consoleLog] = consoleLogger('Geo')
  const [selectedStations, setSelectedStations] = useState(defaultStation ? [defaultStation] : [])
  const [temperature, setTemperature] = useState(defaultStation?.temperature || '')

  const onUpdateStation = (selectedStation) => {
    setByCoordinates({
      latitude: selectedStation.latitude,
      longitude: selectedStation.longitude,
    })
  }
  consoleLog('Selected stations', selectedStations)

  const setDefaultStation = () => {
    if (defaultStation !== undefined) {
      setSelectedStations([defaultStation])
      setTemperature(defaultStation.temperature)
    }
  }

  const setByCoordinates = ({ latitude, longitude }) => {
    if (isLocationInSouthTyrol(latitude, longitude)) {
      let justLatLongValues = { latitude, longitude }
      let minDistanceFinderHelper
      let nearestStation
      data &&
        data.stationsData.map((station) => {
          if (station.category === 1 || station.category === 3) {
            let latLongStation = {
              latitude: parseFloat(station.gps.lat.replace(',', '.')),
              longitude: parseFloat(station.gps.lng.replace(',', '.')),
            }
            if (minDistanceFinderHelper === undefined) {
              minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
              nearestStation = station
            }
            if (geolib && minDistanceFinderHelper > geolib.getDistance(justLatLongValues, latLongStation)) {
              minDistanceFinderHelper = geolib && geolib.getDistance(justLatLongValues, latLongStation)
              nearestStation = station
            }
          }
          return null
        })
      if (nearestStation !== undefined) {
        setSelectedStations([nearestStation])
        setTemperature(nearestStation.temperature)
      }
    } else {
      setDefaultStation()
    }
  }

  return {
    onUpdateStation,
    selectedStations,
    temperature,
  }
}

export default useSelectStation
