import React from 'react'
import PropTypes from 'prop-types'
import MessageError from '../../../../Error/screens/MessageError'
import translate from '../../../../../modules/translate'
import ErrorBoundary from '../../../../Error/widgets/ErrorBoundary'
import SkeletonCouncilElection from '../../skeleton/skeleton'
import loadable from '@loadable/component'

const ElectionResults = loadable(() =>
  import(/* webpackChunkName: "ElectionResults" ,  webpackPrefetch: true */ '../../../components/ElectionResults'),
)

const errorOptions = {
  component: translate('newsfeed_error'),
  logError: 'ElectionScreen screen display',
}

const ElectionScreen = ({ data, loading, error, ready, match, location, ...props }) => {
  if (loading) ElectionResults.preload()
  return (
    <ErrorBoundary {...errorOptions} {...props}>
      {error && <MessageError logInfo={error.message} {...errorOptions} />}
      {loading && <SkeletonCouncilElection location={location} />}
      {data && <ElectionResults data={data} location={location} />}
    </ErrorBoundary>
  )
}

ElectionScreen.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data:  PropTypes.object,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

ElectionScreen.defaultProps = {
  response: {
    data: undefined,
  },
}

export default ElectionScreen
