import React from 'react'
import PropTypes from 'prop-types'
import MessageError from '../../../../Error/screens/MessageError'
import translate from '../../../../../modules/translate'
import ErrorBoundary from '../../../../Error/widgets/ErrorBoundary'
import SkeletonCouncilElection from '../../skeleton/skeleton'
import loadable from '@loadable/component'

const AllMayors = loadable(() =>
  import(/* webpackChunkName: "AllMayors" , webpackPrefetch: true */ '../../../components/AllMayors'),
)

const errorOptions = {
  component: translate('newsfeed_error'),
  logError: 'MayorScreen screen display',
}

const MayorScreen = ({ data, loading, error, ready, match, location, ...props }) => {
  if (loading) AllMayors.preload()
  return (
    <ErrorBoundary {...errorOptions} {...props}>
      {error && <MessageError logInfo={error.message} {...errorOptions} />}
      {loading && <SkeletonCouncilElection location={location} />}
      {data && <AllMayors data={data} location={location} />}
    </ErrorBoundary>
  )
}

MayorScreen.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data: PropTypes.object,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

MayorScreen.defaultProps = {
  response: {
    data: undefined,
  },
}

export default MayorScreen
